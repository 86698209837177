
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}
.color-text-blanco-gracias{
  color: #f8f8f8;
  /* -webkit-text-stroke: 1px #CAB453; */
  /* border-style: ridge;
  border-width: 4px;
  border-color: #d32c0e ; */
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: xx-large;
  text-transform: uppercase;
}
.texto-agradeciiento{
  margin-left: 2em;
  margin-right: 2em;
  color: #f8f8f8;
  font-size: large;

}

.item-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item-center-t{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.caragndo-spin{
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(202,180,83);
  background: radial-gradient(circle, rgba(202,180,83,1) 9%, rgba(10,131,66,1) 46%);
  
}
.terminos-conditions{
  font-size: medium;
  margin-inline: 1.3em !important;
}

.imput-File-hiden{
  display: none;
}
.App-header {
  /* -webkit-background-size: cover ; */
  background-image: url('../assets/fotofondo1.jpg');
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
  background-color: #0A8342;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.capanegra{
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  font-size: calc(10px + 2vmin);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #60726300;
}
.capanegra2{
  min-width: 100%;
  background-color: #202020c4;
}
.App-link {
  color: #61dafb;
}
.siguinte{
  background-color: #0A8342 !important;
  width: 15em;
  height: 3.5em;
  border-radius: 0.7em !important;
}

.selec-item{
  border-radius: 1em;
  background-color: #CAB453;
  width: 15em;
  height: 3em;
}

.form-selec{
  border-radius: 1em;
  border: none;    
  border: '1px solid #ced4da';
  margin: 10px;
  color: #ffffff;
  margin: 50px;
}
.form-selec:focus-visible{
  border-radius: 1em  !important;
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
    
}

.heder-container{
  width: 100%;
  height: 6.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: #0A8342;
  
}

.tex-area-pregunta{
  border-radius: 1em !important; 
  background-color: #C7C5AD;
  border: none !important;

}
.col{
  display: flex;
  flex-direction: column;
}
.p-40{
  padding: 40px;
}
.p-20{
  padding: 20px;
}
.p-10{
  padding: 10px;
}

.row{
  display: flex;
  flex-direction: row;
}
.tex-repont{
  font-size: calc(10px + 2vmin);
}
.pregunta-row{
  margin-bottom: 0.3em;
}
.icon-pregunta{
  /* color: #0A8342; */
  color: #0A8342;
  font-size: 1.9em !important;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.icon-select{
  color: #ffffff;
  border-radius: 3em;
  background-color: #d32c0e;
}
.numeros-circule{
  font-weight: 500;
  width: 14em;
  align-items: center;
  justify-content: space-between;
  font-size: x-large !important;
  
}
.numeros-circule small{
  color: #0A8342 ;
  border: 2px solid #0A8342;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px ;
  height: 48px;
  border-radius: 60px;
  /* background-color: #0A8342; */
}
.numeros-circule-botun{
  color: #ffffff !important;
  background-color: #0A8342 !important;
}

.cagando-trasmp{
  z-index: 100;
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;*/
  background-color: #04130b94;
  /* background: whitesmoke; */
}

.contenedor-modal-cargando {
  position: relative;
}

.body-cragando {
  height: 100%;
  width: 100%;
  z-index: 50;
}

.modal-cargando {
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  color: #CAB453;
  background-color: #073a22b4 ;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  font-size: larger;
  font-weight: 600;
}

.modal-gracias {
  background-image: url(../assets/confeti.gif);
  background-attachment: local;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: rotate(90deg); */
  transform: translate(-50%, -50%);
  z-index: 0;
  color: #FFb81c;
  background-color: #ffffff00 ;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  font-size: larger;
  font-weight: 600;
}


.bienvenido{
  color: #FFb81c;
  text-transform: uppercase;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  /* font-family: Oduda-Bold; */
}




.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #dfc;
  border-color: #CAB453 transparent #CAB453 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}


@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



